import React from 'react'

function Hamburger({isOpen}) {

  return (
    <div className='hamburger'>
        <div className='burger' style={{transform: `${isOpen ? 'rotate(45deg)' : 'rotate(0)'}`}}>
        </div>
        <div className='burger' style={{transform: `${isOpen ? 'translate(100%)' : 'translate(0)'}`, opacity:`${isOpen ? 0 : 1}`}}>
        </div>
        <div className='burger'style={{transform: `${isOpen ? 'rotate(-45deg)' : 'rotate(0)'}`}}>
        </div>

    </div>
  )
}

export default Hamburger
