import { useState, useEffect } from 'react'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"; 

export default function Contactpagev2() {
	const [activeSlide, setActiveSlide] = useState(0)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		// Dynamically add Bootstrap CSS
		const bootstrapCSS = document.createElement("link");
		bootstrapCSS.rel = "stylesheet";
		bootstrapCSS.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
		bootstrapCSS.id = "bootstrap-css";
		document.head.appendChild(bootstrapCSS);

		// Dynamically add Bootstrap JS
		const bootstrapJS = document.createElement("script");
		bootstrapJS.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js";
		bootstrapJS.id = "bootstrap-js";
		document.body.appendChild(bootstrapJS);

		// Set up slider interval
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3);
		}, 5000);

		// Cleanup on component unmount
		return () => {
			document.getElementById("bootstrap-css")?.remove();
			document.getElementById("bootstrap-js")?.remove();
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3)
		}, 5000)
		return () => clearInterval(interval)
	}, [])

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	return (
		<div className="font-sans text-gray-800">
			<nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top">
				<div className="container pt-3 pb-3">
					<a className="navbar-brand" href="#">
						<img
							src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/birdies%20logo.png"
							alt="Birdies Logo"
							style={{   width: 100 }}
						/>
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon" />
					</button>
					<div
						className="collapse navbar-collapse justify-content-end"
						id="navbarNav"
					>
						<ul className="navbar-nav align-items-center">
							<li className="nav-item">
								<a className="nav-link active" href="/" style={{ color: "#333" }}>
									Home
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/about" style={{ color: "#333" }}>
									About Us
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#locations" style={{ color: "#333" }}>
									Locations
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#careers" style={{ color: "#333" }}>
									Careers
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/login" style={{ color: "#333" }}>
									Sign in
								</a>
							</li>
							<li className="nav-item">
								<a
									className="btn"
									href="/contact"
									style={{
										backgroundColor: "#4169E1",
										color: "#fff",
										borderRadius: 50,
										padding: "12px 30px"
									}}
								>
									Contact Us
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<section id="careers" style={{ padding: "120px 0", marginTop: 75, paddingBottom: 75 }}>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Join the Birdies Team
					</h2>
					<div className="row">
						<div className="col-md-6">
							<p>
								Looking for a rewarding career? Birdies is always seeking motivated
								individuals to join our team across our 109 locations. Be a part of a
								company that values professionalism, customer service, and community
								involvement.
							</p>
							{/* Application Form */}
							<form action="#" method="post" encType="multipart/form-data">
								<div className="mb-3">
									<label htmlFor="applicantName" className="form-label">
										Full Name
									</label>
									<input
										type="text"
										className="form-control"
										id="applicantName"
										name="applicantName"
										required=""
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="applicantEmail" className="form-label">
										Email Address
									</label>
									<input
										type="email"
										className="form-control"
										id="applicantEmail"
										name="applicantEmail"
										required=""
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="applicantPhone" className="form-label">
										Phone Number
									</label>
									<input
										type="tel"
										className="form-control"
										id="applicantPhone"
										name="applicantPhone"
										required=""
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="position" className="form-label">
										Position Applying For
									</label>
									<input
										type="text"
										className="form-control"
										id="position"
										name="position"
										required=""
									/>
								</div>
								<div className="mb-3">
									<label htmlFor="resume" className="form-label">
										Upload Resume
									</label>
									<input
										type="file"
										className="form-control"
										id="resume"
										name="resume"
										accept=".pdf,.doc,.docx"
									/>
								</div>
								<button
									type="submit"
									className="btn mt-3"
									style={{ backgroundColor: "#4169E1", color: "#fff" }}
								>
									Submit Application
								</button>
							</form>
						</div>
						<div className="col-md-6">
							<img
								src="https://cdn.shopify.com/s/files/1/0617/2517/9994/files/DALL_E_2024-10-21_14.46.09_-_An_image_for_a_job_application_section_on_a_convenience_store_chain_website_titled_Join_Our_Team_._The_setting_features_a_modern_convenience_store_en.webp?v=1729536756"
								alt="Join Our Team"
								className="img-fluid rounded shadow"
							/>
						</div>
					</div>
				</div>
			</section>


			<footer
				className="mt-5"
				style={{ backgroundColor: "#333", color: "#fff", padding: "40px 0" }}
			>
				<div className="container text-center">
					<p>© 2024 Birdies Gas Stations. All rights reserved.</p>
					<p>
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Privacy Policy
						</a>{" "}
						|{" "}
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Terms of Service
						</a>
					</p>
				</div>
			</footer>
		</div>
	)
}