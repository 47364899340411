import { useState, useEffect } from 'react'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Aboutpagev2() {
	const [activeSlide, setActiveSlide] = useState(0)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		// Dynamically add Bootstrap CSS
		const bootstrapCSS = document.createElement("link");
		bootstrapCSS.rel = "stylesheet";
		bootstrapCSS.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
		bootstrapCSS.id = "bootstrap-css";
		document.head.appendChild(bootstrapCSS);

		// Dynamically add Bootstrap JS
		const bootstrapJS = document.createElement("script");
		bootstrapJS.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js";
		bootstrapJS.id = "bootstrap-js";
		document.body.appendChild(bootstrapJS);

		// Set up slider interval
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3);
		}, 5000);

		// Cleanup on component unmount
		return () => {
			document.getElementById("bootstrap-css")?.remove();
			document.getElementById("bootstrap-js")?.remove();
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3)
		}, 5000)
		return () => clearInterval(interval)
	}, [])

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	return (
		<div className="font-sans text-gray-800">
			<nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top">
				<div className="container pt-3 pb-3">
					<a className="navbar-brand" href="#">
						<img
							src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/birdies%20logo.png"
							alt="Birdies Logo"
							style={{   width: 100 }}
						/>
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon" />
					</button>
					<div
						className="collapse navbar-collapse justify-content-end"
						id="navbarNav"
					>
						<ul className="navbar-nav align-items-center">
							<li className="nav-item">
								<a className="nav-link active" href="/" style={{ color: "#333" }}>
									Home
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/about" style={{ color: "#333" }}>
									About Us
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/#locations" style={{ color: "#333" }}>
									Locations
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/#careers" style={{ color: "#333" }}>
									Careers
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/login" style={{ color: "#333" }}>
									Sign in
								</a>
							</li>
							<li className="nav-item">
								<a
									className="btn"
									href="/contact"
									style={{
										backgroundColor: "#4169E1",
										color: "#fff",
										borderRadius: 50,
										padding: "12px 30px"
									}}
								>
									Log in
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<section id="about" style={{ padding: "120px 0", marginTop: '5rem' }} >
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						About Us
					</h2>
					<p style={{ maxWidth: 800, margin: "0 auto", textAlign: "center" }}>
						Birdies started its journey in Maryland as NSR Petro Services, with a
						vision to provide top-notch fuel and convenience store services. Our first
						major acquisition was Burchmarts in Southern Maryland, which helped us
						expand our reach and offerings. In 2022, we continued our growth by
						acquiring Holt C-Stores in North Carolina. Today, with over 109 locations
						across Maryland, Virginia, DC, and North Carolina, Birdies remains
						committed to delivering quality fuel, fresh food, and exceptional customer
						service.
					</p>
				</div>
			</section>

			<footer
				className="mt-5"
				style={{ backgroundColor: "#333", color: "#fff", padding: "40px 0" }}
			>
				<div className="container text-center">
					<p>© 2024 Birdies Gas Stations. All rights reserved.</p>
					<p>
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Privacy Policy
						</a>{" "}
						|{" "}
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Terms of Service
						</a>
					</p>
				</div>
			</footer>
		</div>
	)
}