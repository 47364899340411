import { useState, useEffect } from 'react'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"; 
import './index.css'
export default function Homepagev2() {
	const [activeSlide, setActiveSlide] = useState(0)
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	useEffect(() => {
		// Dynamically add Bootstrap CSS
		const bootstrapCSS = document.createElement("link");
		bootstrapCSS.rel = "stylesheet";
		bootstrapCSS.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css";
		bootstrapCSS.id = "bootstrap-css";
		document.head.appendChild(bootstrapCSS);

		// Dynamically add Bootstrap JS
		const bootstrapJS = document.createElement("script");
		bootstrapJS.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js";
		bootstrapJS.id = "bootstrap-js";
		document.body.appendChild(bootstrapJS);

		// Set up slider interval
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3);
		}, 5000);

		// Cleanup on component unmount
		return () => {
			document.getElementById("bootstrap-css")?.remove();
			document.getElementById("bootstrap-js")?.remove();
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveSlide((prev) => (prev + 1) % 3)
		}, 5000)
		return () => clearInterval(interval)
	}, [])

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	const carouselItems = [
		{
			image: 'https://cdn.shopify.com/s/files/1/0617/2517/9994/files/WhatsApp_Image_2024-10-21_at_2.25.55_PM.jpg?v=1729535445',
			title: 'Welcome to Birdies',
			description: 'Your one-stop destination for fuel, food, and service with a smile.',
			buttonText: 'Learn More',
			buttonHref: '#mission',
		},
		{
			image: 'https://cdn.shopify.com/s/files/1/0617/2517/9994/files/WhatsApp_Image_2024-10-21_at_2.25.56_PM_1.jpg?v=1729535445',
			title: 'Quality Fuel',
			description: 'Refueling with trusted brands like Sunoco, Shell, or Exxon.',
			buttonText: 'Fuel Cards',
			buttonHref: '#fuel-cards',
		},
		{
			image: 'https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/DALL_E_2024-10-21_14.38.48_-_A_vibrant_appetizing_image_showcasing_a_variety_of_fresh_and_delicious_food_options_for_online_ordering._The_spread_includes_gourmet_burgers_fresh_s.webp',
			title: 'Delicious Food',
			description: 'Grab a fresh meal on the go or order online.',
			buttonText: 'Order Now',
			buttonHref: '#online-ordering',
		},
	]

	return (
		<div className="font-sans text-gray-800">
			<nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm fixed-top">
				<div className="container pt-3 pb-3">
					<a className="navbar-brand" href="#">
						<img
							src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/birdies%20logo.png"
							alt="Birdies Logo"
							style={{   width: 100 }}
						/>
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon" />
					</button>
					<div
						className="collapse navbar-collapse justify-content-end"
						id="navbarNav"
					>
						<ul className="navbar-nav align-items-center">
							<li className="nav-item">
								<a className="nav-link active" href="/" style={{ color: "#333" }}>
									Home
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" style={{ color: "#333" }} href="/about">
									About Us
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#locations" style={{ color: "#333" }}>
									Locations
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#careers" style={{ color: "#333" }}>
									Careers
								</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="/login" style={{ color: "#333" }}>
									Sign in
								</a>
							</li>
							<li className="nav-item">
								<a
									className="btn"
									href="/contact"
									style={{
										backgroundColor: "#4169E1",
										color: "#fff",
										borderRadius: 50,
										padding: "12px 30px"
									}}
								>
									Contact Us
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<div
				id="heroCarousel"
				className="carousel slide carousel-fade"
				data-bs-ride="carousel"
				style={{ marginTop: 75 }}
			>
				<div className="carousel-inner">
					{/* Slide 1 */}
					<div
						className="carousel-item active"
						style={{
							height: "80vh",
							minHeight: 400,
							background:
								'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/WhatsApp_Image_2024-10-21_at_2.25.55_PM.webp") no-repeat center center',
							backgroundSize: "cover",
							position: "relative"
						}}
					>
						<div className="carousel-caption" style={{ bottom: 200 }}>
							<h1
								style={{ fontSize: "4rem", fontWeight: "bold", textShadow: "none" }}
							>
								Welcome to Birdies
							</h1>
							<p style={{ fontSize: "1.5rem", textShadow: "none" }}>
								Your one-stop destination for fuel, food, and service with a smile.
							</p>
							<a
								href="#mission"
								className="btn mt-3"
								style={{
									backgroundColor: "#4169E1",
									color: "#fff",
									borderRadius: 50,
									padding: "12px 30px"
								}}
							>
								Learn More
							</a>
						</div>
					</div>
					{/* Slide 2 */}
					<div
						className="carousel-item"
						style={{
							height: "80vh",
							minHeight: 400,
							background:
								'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/WhatsApp_Image_2024-10-21_at_2.25.56_PM_1.webp") no-repeat center center',
							backgroundSize: "cover",
							position: "relative"
						}}
					>
						<div className="carousel-caption" style={{ bottom: 200 }}>
							<h1
								style={{ fontSize: "4rem", fontWeight: "bold", textShadow: "none" }}
							>
								Quality Fuel
							</h1>
							<p style={{ fontSize: "1.5rem", textShadow: "none" }}>
								Refueling with trusted brands like Sunoco, Shell, or Exxon.
							</p>
							<a
								href="#fuel-cards"
								className="btn mt-3"
								style={{
									backgroundColor: "#4169E1",
									color: "#fff",
									borderRadius: 50,
									padding: "12px 30px"
								}}
							>
								Fuel Cards
							</a>
						</div>
					</div>
					{/* Slide 3 */}
					<div
						className="carousel-item"
						style={{
							height: "80vh",
							minHeight: 400,
							background:
								'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/DALL_E_2024-10-21_14.38.48_-_A_vibrant_appetizing_image_showcasing_a_variety_of_fresh_and_delicious_food_options_for_online_ordering._The_spread_includes_gourmet_burgers_fresh_s.webp") no-repeat center center',
							backgroundSize: "cover",
							position: "relative"
						}}
					>
						<div className="carousel-caption" style={{ bottom: 200 }}>
							<h1
								style={{ fontSize: "4rem", fontWeight: "bold", textShadow: "none" }}
							>
								Delicious Food
							</h1>
							<p style={{ fontSize: "1.5rem", textShadow: "none" }}>
								Grab a fresh meal on the go or order online.
							</p>
							<a
								href="#online-ordering"
								className="btn mt-3"
								style={{
									backgroundColor: "#4169E1",
									color: "#fff",
									borderRadius: 50,
									padding: "12px 30px"
								}}
							>
								Order Now
							</a>
						</div>
					</div>
				</div>
				{/* Controls */}
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#heroCarousel"
					data-bs-slide="prev"
				>
					<span className="carousel-control-prev-icon" />
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#heroCarousel"
					data-bs-slide="next"
				>
					<span className="carousel-control-next-icon" />
				</button>
			</div>

			<section id="mission" style={{ padding: "80px 0" }}>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Our Mission
					</h2>
					<p className="text-center mx-auto" style={{ maxWidth: 800 }}>
						At Birdies, we pride ourselves on providing high-quality fuel and
						excellent customer service across 109 locations in Maryland, Virginia,
						DC, and North Carolina. Whether you're refueling with trusted brands
						like Sunoco, Shell, or Exxon, or grabbing a fresh meal on the go, our
						commitment to professionalism and convenience is always front and
						center. We're more than a gas station—we're your one-stop destination
						for fuel, food, and service with a smile.
					</p>
				</div>
			</section>

			<section style={{ padding: "80px 0", backgroundColor: "#f9f9f9" }}>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<img
								src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/WhatsApp_Image_2024-10-21_at_2.25.56_PM_2.jpg"
								alt="Quality Service"
								className="img-fluid rounded shadow"
							/>
						</div>
						<div className="col-md-6">
							<h3 style={{ color: "#004A98" }}>
								Professionalism and Quality Service
							</h3>
							<p>
								At Birdies, we believe that every stop at our stations should be a
								seamless experience. With clean, well-maintained locations and a
								team dedicated to excellent service, we go beyond just filling your
								tank. Our food offerings are crafted for busy travelers and local
								commuters alike—serving fresh, hot meals to keep you going.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section id="online-ordering" style={{ padding: "80px 0" }}>
				<div className="container text-center">
					<h2 style={{ fontSize: "2.5rem", marginBottom: 40, color: "#004A98" }}>
						Order Food Online
					</h2>
					<p className="mb-5">
						Enjoy our delicious offerings from the comfort of your home or on the
						go.
					</p>
					<img
						src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/DALL_E_2024-10-21_14.38.48_-_A_vibrant_appetizing_image_showcasing_a_variety_of_fresh_and_delicious_food_options_for_online_ordering._The_spread_includes_gourmet_burgers_fresh_s.webp"
						alt="Delicious Food"
						className="img-fluid rounded shadow mb-4"
						style={{ maxWidth: 600, width: "100%", height: "auto" }}
					/>
					<div>
						<a
							href="#"
							className="btn btn-lg"
							style={{
								backgroundColor: "#4169E1",
								color: "#fff",
								borderRadius: 50,
								padding: "12px 30px"
							}}
						>
							Order Now
						</a>
					</div>
				</div>
			</section>

			<section
				id="careers"
				style={{ padding: "80px 0", backgroundColor: "#f9f9f9" }}
			>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Join the Birdies Team
					</h2>
					<div className="row align-items-center">
						<div className="col-md-6">
							<p>
								Looking for a rewarding career? Birdies is always seeking motivated
								individuals to join our team across our 109 locations. Be a part of
								a company that values professionalism, customer service, and
								community involvement. Apply today to become a part of our growing
								family.
							</p>
							<a
								href="#contact"
								className="btn mt-3"
								style={{
									backgroundColor: "#4169E1",
									color: "#fff",
									borderRadius: 50,
									padding: "12px 30px"
								}}
							>
								Apply Now
							</a>
						</div>
						<div className="col-md-6">
							<img
								src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/DALL_E_2024-10-21_14.46.09_-_An_image_for_a_job_application_section_on_a_convenience_store_chain_website_titled_Join_Our_Team_._The_setting_features_a_modern_convenience_store_en.webp"
								alt="Join Our Team"
								className="img-fluid rounded shadow"
							/>
						</div>
					</div>
				</div>
			</section>

			<section id="fuel-cards" style={{ padding: "80px 0" }}>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Birdies Fuel Cards
					</h2>
					<div className="row align-items-center">
						<div className="col-md-6">
							<img
								src="https://birdiesstore.nyc3.cdn.digitaloceanspaces.com/images/DALL_E_2024-10-11_23.09.48_-_A_detailed_image_of_a_gas_pump_with_the_Birdies_logo_featuring_a_red_and_yellow_bird_placed_prominently_on_the_front_of_the_pump._The_pump_should_b.webp"
								alt="Fuel Cards"
								className="img-fluid rounded shadow"
							/>
						</div>
						<div className="col-md-6">
							<p>
								Manage your fleet or personal fuel expenses with ease using Birdies
								Fuel Cards. Accepted at all Birdies locations, our fuel cards
								provide convenience, control, and savings—whether you’re fueling up
								with Sunoco, Shell, or Exxon. Learn more about how you can
								streamline your fuel management today.
							</p>
							<a
								href="#contact"
								className="btn mt-3"
								style={{
									backgroundColor: "#4169E1",
									color: "#fff",
									borderRadius: 50,
									padding: "12px 30px"
								}}
							>
								Learn More
							</a>
						</div>
					</div>
				</div>
			</section>

			<section
				id="locations"
				style={{ padding: "80px 0", backgroundColor: "#f9f9f9" }}
			>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Find a Birdies Near You
					</h2>
					<p className="text-center mb-5">
						With 109 locations across Maryland, Virginia, DC, and North Carolina,
						we're always nearby.
					</p>
					{/* You can integrate a map or a list of locations here */}
					<div className="text-center">
						<a
							href="#contact"
							className="btn btn-lg"
							style={{
								backgroundColor: "#4169E1",
								color: "#fff",
								borderRadius: 50,
								padding: "12px 30px"
							}}
						>
							View Locations
						</a>
					</div>
				</div>
			</section>

			<section id="contact" style={{ padding: "80px 0" }}>
				<div className="container">
					<h2
						style={{
							fontSize: "2.5rem",
							marginBottom: 40,
							textAlign: "center",
							color: "#004A98"
						}}
					>
						Get in Touch
					</h2>
					<p className="text-center mb-5">
						We'd love to hear from you. Reach out with any questions or comments.
					</p>
					{/* Contact Form (optional) */}
					<div className="row justify-content-center">
						<div className="col-md-8">
							{/* You can integrate a contact form here */}
							<div className="text-center">
								<a
									href="mailto:info@birdies.com"
									className="btn btn-lg"
									style={{
										backgroundColor: "#4169E1",
										color: "#fff",
										borderRadius: 50,
										padding: "12px 30px"
									}}
								>
									Email Us
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<footer
				className="mt-5"
				style={{ backgroundColor: "#333", color: "#fff", padding: "40px 0" }}
			>
				<div className="container text-center">
					<p>© 2024 Birdies Gas Stations. All rights reserved.</p>
					<p>
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Privacy Policy
						</a>{" "}
						|{" "}
						<a href="#" style={{ color: "#FED500", textDecoration: "none" }}>
							Terms of Service
						</a>
					</p>
				</div>
			</footer>
		 
		</div>
	)
}