import React, { useEffect, useState , useRef} from "react";
import { Row, Col, notification, Modal, Dropdown, Menu } from "antd";
import AxiosCall from "../../config/axios";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import moment from 'moment'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function SignagePlaylist(props) {
  var vidRef = useRef(null);
  var refs = [useRef(null),useRef(null),useRef(null),useRef(null),useRef(null)]
  // const [refs, setRefs] = useState([]);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [active, setActive] = useState({
    activeSlide: 0,
    activeSlide2: 0
  })
  const [settings, setSettings] = useState({
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    className: "x-slick",
    dots: false,
    beforeChange: (current, next) => setActive({...active, activeSlide: next }),
    afterChange: current => setActive({ ...active, activeSlide2: current })
  });

  useEffect(() => {
    setLoading(true)
    getScreens();
    setInterval(() => {
      getScreens()
    }, 50000);
  }, []);

  useEffect(() => {
    if(contents.length ){
      if(contents[active?.activeSlide2]?.url?.search("video") != '-1' ){
        refs[active?.activeSlide2].current.pause();
        refs[active?.activeSlide2].current.currentTime = 0;
        refs[active?.activeSlide2].current.load();
        refs[active?.activeSlide2]?.current?.play();
      }else{
        console.log(active?.activeSlide2)
        refs[active?.activeSlide2]?.current?.pause();
        // refs[active?.activeSlide2].current.currentTime = 0;
        refs[active?.activeSlide2]?.current?.load();


      }
      setSettings({
        ...settings,
        autoplaySpeed: contents[active.activeSlide2].duration * 1000
      })
    }
  }, [active.activeSlide2, videoLoaded])
  const getScreens = async () => {
    setLoading(true);
    try {
      const callObj = {
        method: "GET",
        path: `signage/fetchPlaylist?slug=${props.match.params?.slug}`,
      };
      var res = await AxiosCall(callObj);
      var con = []

      res.screens.map(s=>{
        s.folders.map(f=>{
          f.contents.map(c=>{
            var shouldShowToday 
            var inTimeRange 
           var push =false
            if(c.days?.length > 0){
              if(c.days.includes(moment().format("dddd").toLowerCase())){
               shouldShowToday = true
              } else shouldShowToday =false
            }
            if(c.to && c.from){
              var from =  moment().set("hour", c.from.split(':')[0]).set("minute",  c.from.split(':')[1])
              var to =  moment().set("hour", c.to.split(':')[0]).set("minute",  c.to.split(':')[1])
              if(moment().isBetween(from, to)){
                inTimeRange = true
              } else inTimeRange = false
            }
            if(shouldShowToday && inTimeRange){
              con.push({...c,size:s.size})   
            } else if(shouldShowToday !== false && inTimeRange  ){
            con.push({...c,size:s.size})   
            }else if(inTimeRange !== false && shouldShowToday  ){
              con.push({...c,size:s.size})   
            }else if(shouldShowToday !== false && inTimeRange !== false){
              con.push({...c,size:s.size})   
            }
          })
        })
      })
      // con.map(c=>{
      //   refs.push()
      // }) 
      if(con.length == 1){
        setContents([...con,...con]) 
      }else{
        setContents(con) 
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  // const resize = (url, size) => {
  //   var sizeArr = size.split('x')
  //   var urlArr = url.split('upload')
  //   if(sizeArr.length != 2) return url
  //   var newUrl = urlArr[0] + `upload/w_${sizeArr[0].trim()},h_${sizeArr[1].trim()},c_fill,dpr_auto` + urlArr[1]
   
  //   return newUrl
  // }
  return (
    <> 
      <div className="x-signage-playlist">
    
        <Slider {...settings}>
          {contents.map((c,i)=>(
            <div key={i}>
           {c.url.search("video") != '-1' ? 
           <video autoPlay 
           muted={true} 
           ref={refs[i]}
           >
           <source src={c.url} type="video/mp4" />
         </video>
          : 
          <img src={c.url} key={i}/>}
          
          </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

 
