import React from 'react'
import { Link } from 'react-router-dom'




function Footer() {
  return (
    <footer className='footbody'>
      <aside >
         <h1 className='footheader'>

           Quick links
         </h1>
         
          <p className='linkparaf'>
            <Link to="/storeLocator" className='quicklink'>Home</Link>
            <Link to="/about" className='quicklink'>About Us</Link>
            <Link to="/storeLocator" className='quicklink'>Store Locator</Link>
            <Link to="/careers" className='quicklink'>Careers</Link>
            <Link to="/login"className='quicklink'>Login</Link>
          </p>
         
         <div>
              <a href="https://www.twitter.com">
                <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678726926/birdies/twitter_c_xcb7nu.png'  alt='twitter' className='f-span'/>
              </a>
              <a href="https://www.facebook.com">
              <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678726978/birdies/facebook_c_klai6k.png' alt='facebook' className='social_span'/>
              </a>
              <a href="https://www.youtube.com">
                <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678726990/birdies/Youtube_c_dey3bf.png' alt='youtube' className='social_span'/>
              </a>
              <a href="https://www.instagram.com">
                <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678726956/birdies/instagram_c_avycyk.png' alt='instagram' className='social_span'/>
              </a>
              <a href="https://www.linkedin.com">
                <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678726940/birdies/linkedin_c_nhb1lf.png' alt='linkedin' className='social_span'/>
              </a>
         </div>
         <p className='copyright'>
          &copy; 2023 Birdies Store, All rights reserved
         </p>
      </aside>
      <aside >
        <h1 className='footheader'>
           Contact info:
        </h1>
        <span className='contactSpanf'>
        <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714290/birdies/Location_xrqud7.png' alt=''/>
          <p className='contact'>
            7303 Hanover Pkwy Greenbelt MD, 20770
          </p>
        </span>
        <span className='contactSpan'>
        <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714419/birdies/Stroke_5_hyswqs.png'  alt=''/>
          <p className='contact'>
            +(301) 9825 400
          </p>
        </span>
        <span className='contactSpan'>
        <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714332/birdies/Message_albqwz.png' alt=''/>
          <p className='contact'>
             support@birdiesstore.com
          </p>
        </span>
      </aside>
    </footer>
  )
}

export default Footer
