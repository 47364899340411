/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import Axios from 'axios';
import api from './api';
export const baseURL = api+'/api';

const AxiosCall = async callObj => {

  const { path, method, data, contentType, baseUrlType = null } = callObj;

  const token = window.localStorage.getItem('token');
  const headers = {
    Authorization: `${token}`,
    // 'Content-Type': contentType || 'application/json'
  };

  let url = `${baseURL}/${path}`;


  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;
    result.refreshedToken && window.localStorage.setItem('token', result.refreshedToken)
    return result;
  } catch (error) {
    if (error.response) {
      const { refresh } = error.response.data;
      refresh && window.location.assign("/login")
    } 
    throw error;
  }
};
export default AxiosCall;
