import React, { useEffect , lazy, Suspense} from "react"
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom"

import AOS from "aos"
import './App.css'
import 'aos/dist/aos.css';
import { LoadingOutlined } from "@ant-design/icons";
import Homepage2nd  from "./pages/homepage/homepage2nd"
import SignagePlaylist from "./pages/signage/index.js"
import Homepagev2 from "./pages/newouterpages/homepagev2.jsx";
import Aboutpagev2 from "./pages/newouterpages/aboutpagev2.jsx";
import Contactpagev2 from "./pages/newouterpages/contactpagev2 .jsx";
const Deals  = lazy(()=> import ("./pages/Deals/index.jsx"))
const Careers  = lazy(()=> import ("./pages/careers/index.jsx"))
const AboutTwo  = lazy(()=> import ("./pages/about/about"))
const Homepage  = lazy(()=> import ('./pages/homepage/homepage'))
// const Careers  = lazy(()=> import ("./pages/careers/careers"
const Careerdetails  = lazy(()=> import ('./pages/careerdetails/careerdetails'))
const Login  = lazy(()=> import ('./pages/login/login'))
const Dashboard  = lazy(()=> import ("./pages/dashboard/dashboard"))
const VendorDashboard  = lazy(()=> import ("./pages/VendorDashboard"))
const DashboardLayout  = lazy(()=> import ("./Layout/DashboardLayout"))
const About  = lazy(()=> import ("./pages/about/index.js"))
const StoreLocator  = lazy(()=> import ("./pages/storeLocator/index.js"))
const StoreLocator2nd  = lazy(()=> import ("./pages/storeLocator"))
const Check  = lazy(()=> import ("./components/checks/check"))
// const OneSignal  = lazy(()=> import 'react-onesignal';
const TicketApproval  = lazy(()=> import ("./pages/ticketApproval/index.js"))
const UploadWorkOrder  = lazy(()=> import ("./pages/ticketApproval/uploadWorkOrder"))
const Invoice  = lazy(()=> import ("./components/resources/invoice"))
const NewTicket  = lazy(()=> import ("./pages/ticketApproval/NewTicket.jsx"))

function App() {

  useEffect(() => {
    AOS.init();
  }, [])
  // useEffect(async () => {
  //   // await OneSignal.init({ appId: "6ccaeebf-6210-4c82-8da5-6752c425829f" });
  // }, [])
  return (
    
    <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="deals" component={Deals}/>
        <Route exact path="/careers-details/:slug" component={Careerdetails} />
        <Route exact path="/careers" component={Careers} />
        {/* <Route exact path="/about" component={AboutTwo} /> */}
				<Route exact path="/about" component={Aboutpagev2} />
        <Route exact path="/storeLocator" component={StoreLocator2nd} />
        <Route exact path="/vendor/login" component={Login} />
        <Route exact path="/rejectTicket" component={TicketApproval} />
        <Route exact path="/acceptTicket" component={TicketApproval} />
        <Route exact path="/ticket/new" component={NewTicket}/>
        <Route exact path="/signage/:slug" component={SignagePlaylist} />
        <Route exact path="/uploadWorkOrder" component={UploadWorkOrder} />
        <Route exact path={`/invoice/:ticketNumber`} component={Invoice} />
        <Route
            path={`/viewcheck`}
            exact
            render={(props) => <Check 
            {...props} 
            />}
          />
        <Route  path="/vendor/dashboard"
          component={(props) => <DashboardLayout {...props}>
            <VendorDashboard {...props} />
          </DashboardLayout>} />
          <Route  path="/dashboard"
          component={(props) => <DashboardLayout {...props}>
            <Dashboard {...props} />
          </DashboardLayout>} />
        <Route exact path="/contact" component={Contactpagev2} />
				<Route exact path="/" component={Homepagev2} /> 
				
        



        
        <Redirect path="*" to="/" />
      </Switch>
    </BrowserRouter>
    </Suspense>
  );
}

const Loader = () => <div style={{display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", width: "100%", height: "100%", fontSize: '2em'}}><LoadingOutlined  /></div>
export default App;
