import Footer from '../../components/footer/footer2nd'
import Nav from '../../components/nav/nav2nd'
import React from 'react'
import { Row, Col } from 'antd'



const Deals = () => {
const data = [
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },
  {
    image:'https://res.cloudinary.com/dzvhmiutm/image/upload/v1679907800/birdies/Pepsi_mxwsv6.png',
    title: 'Pepsi 2/3.75',
    description: 'Pepsi 20oz'
  },

]

  return (
    <div>
      <Nav />
      <div className='shelvers'>
        <h1 className='center-header'>
          Deals
        </h1>
      </div>
      {/* <img src="" alt="Goods shelf pictures" /> */}
      <Row  gutter={[24, 24]} className='holder-row'> 
            {

              data.map((item, index) => {
                return (
                  <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8} className='holder-col'>
                        <div className='product-card'>
                           <div className='product-card-image'>
                              <img src={item.image} alt="product" style={{width: '100%', height:'auto'}}/>
                           </div>
                           <div className='product-card-content'>
                               <h3 className='title'>{item.title}</h3>
                               <p className='description'>
                                {item.description}
                               </p>
                           </div>
                       </div>
                  </Col>
                )
              })
            } 
      </Row>
      <Footer />
    </div>
  )
}

export default Deals