import React from 'react'
import Nav from '../../components/nav/nav2nd'
import Footer from '../../components/footer/footer2nd'
import { Row, Col, Button, Input, TextArea, Tooltip } from 'antd'
import {BrowserRouter as Router, Link, Switch, useRouteMatch, Route, BrowserRouter } from 'react-router-dom'
import Deals from '../Deals'


function Homepage2nd() {
 


const { TextArea } = Input;

  return (
    <main className='HomeContainer'>
        <Nav/>
        <Row  className='family'>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='l-aside'>
              <div className='l-div'>
                <div className='combined'>
                <Col span={20} offset={2}>
                <header className='h-family'>
                  We make your life easy
               </header>
                </Col> 
               <Col span={10}  xs={{offset:7}}  middle className='red-line'>
                
                <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714381/birdies/red_line_uenykq.png' alt="red line" className='red-lina'/>
                

               </Col>
                </div>
               <Col span={20} offset={2}>
               <p className='aboout-p'>
                  Birdies is a privately held company that provide fuel, beverages, snacks, hot food, and countless other items to make your life easier.
               </p>
               </Col>
               
               </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='r-side'>
              <div>
              <img src='https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714145/birdies/family_88_hnwgqm.png' alt="family_picture" className='family-image'/>
              </div>
            </Col>
        </Row>
        <div className='logos-div'>
                    <header className='logos-header'>
                      <h1>
                         Trusted by campanies like
                      </h1>
                    </header>
              <Row  align={'middle'} justify={'center'}>
                <Col xs={24} sm={24} md={12} lg={12} xl={4}>
                <div className='image-img-div'>
                <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1680181827/birdies/Shell_crqbsi.png" alt="shell gas station logo" className='image-img'/>
                </div>
                </Col>
                <Col  xs={24} sm={24} md={12} lg={12} xl={4}>
                <div className='image-img-div fortop-768'>
                <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178173/birdies/sunoco_vw0x16.png" alt="sunoco company logo" className='image-img'/>
                </div>
                </Col>
                <Col  xs={24} sm={24} md={12} lg={12} xl={4}>
                <div className='image-img-div for-top'>
                <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178121/birdies/exxon_brdli8.png" alt="exxon logo" className='image-img'/>
                </div>
                </Col>
                <Col  xs={24} sm={24} md={12} lg={12} xl={4}>
                <div className='image-img-div for-top'>
                <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178101/birdies/crunchy-small_quv7sy.png" alt="crunchy logo" className='image-img'/>
                </div>
                </Col>
                <Col  xs={24} sm={24} md={12} lg={12} xl={4}>
                <div className='image-img-div for-top'>
                <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1680178161/birdies/subway-small_nqgbbm.png" alt="subway logo" className='image-img'/>
                </div>
                </Col>
              </Row>
        </div>
        <div className='offer-div'>
            <h1 className='offer'>
              What we offer
            </h1>
        </div>
        <section className='choppy'>
            <div className='pizza_combo' >
            <div className='half_pizza'>
             <h3 className='p-pizza'>
               Pizza
             </h3>
            </div>
            <div className='full_pizza'>
            </div>
            </div>
            <div className='subcrunchy'>
            <div className='subway'>
               <h3  className='p-sub'>
                 Subway
               </h3>
            </div>
            <div className='krunchy'>
               <h3 className='p-sub'>
                 Krispy Krunchy
               </h3>
            </div>
            </div>
            {/* <div className='peso'>
               <h3 className='p-subp'>
                  Peso 2/3.75 & Gatorade 2/5.50 deal
               </h3>
            </div> */}
        </section>
        <section className='restaurant'>
            <div className='coffeWoman' style={{ backgroundImage: "url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1678717499/birdies/coffeWoman_fm6mum.png)" }}>
              <aside className='blueAside'>
              <h3 className='h-coffee'>
                Try our fresh new coffee everyday
              </h3>
              </aside>
            </div>
            <div className='threedivs'>
              <div className='icecream' style={{ backgroundImage: "url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714227/birdies/icecream_z5dhtq.png)" }}>

              </div>
              <div className='coffe' style={{ backgroundImage: "url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714104/birdies/coffee_djsuek.png)" }}>

              </div>
              <div className='coffeJar' style={{ backgroundImage: "url(https://res.cloudinary.com/dzvhmiutm/image/upload/v1678714121/birdies/coffeJar_rsp1p5.png)" }}>

              </div>
            </div>
        </section>
        <Row   className='services'>
            <Col  xs={24} sm={24} md={12} lg={12}>
              <div className='male-cook'>
              <Tooltip title='click to navigate'>
                  <Link to={{pathname:'/about'}} className='image-center-h1'>
                      Who we are
                  </Link>
              </Tooltip>
              
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='shelves'>
                 <Tooltip title='click to navigate'>
                    <Link to={{pathname:'/deals'}} className='image-center-h1'>
                       Deals
                    </Link>
                 </Tooltip>
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='gas_staion-pic'>
              <Tooltip title='click to navigate'>
              <Link to={{pathname:'/storeLocator'}}  className='image-center-h1'>
                  Locate Birdies
              </Link>
              </Tooltip>
                
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='female-baker-pix'>
                <Tooltip title="click to navigate">
                <a href='#details' className='image-center-h1'>
                  Contact Us
                 </a>
                </Tooltip>
              
              </div>
            </Col>
        </Row>
        <section className='debitCard'>
            <div className='card-div'>
                <h3 className='h-card'>
                  Birdies In-house fuel cards
                </h3>
                <Button className='btn-card'>
                    Open fuel account
                </Button>
            </div>   
        </section>
        <Row  className='userDetail' id='details'>
            <Col xs={24} sm={24} md={12} lg={12} className='userInputs'>
              <div className='userInput-div'>
                <header className='h1-holder'>
                <h3 className='h-input'>
                Get in touch
               </h3>
                </header>
               <div className='all-input'>
               <Input placeholder='Your name' className='i-input'/>
               <Input placeholder='Your Email' className='i-input'/>
               <Input placeholder='Subject' className='i-input'/>
               <TextArea  rows={5} placeholder='Message' className='i-input r-size'/>
               </div>
               <Button className='btn-send'>
                  Send Message
               </Button>

              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} push={1}  className='a-image'>
              <div className='google-map-div'>
              </div>
            </Col>
        </Row>
        <Footer/>
    </main>
  )
}

export default Homepage2nd
